import { type InputHTMLAttributes } from "react";
import MagnifyingGlass from "public/svgs/magnifyingGlass.svg";

import "./searchStyle.css";

export type SearchProps = InputHTMLAttributes<HTMLInputElement> & {
  inputRef?: React.RefObject<HTMLInputElement>;
};
const Search: React.FC<SearchProps> = ({ inputRef, className, ...props }) => {
  return (
    <form className={className} onSubmit={(e) => e.preventDefault()}>
      <label
        htmlFor="default-search"
        className="sr-only mb-2 text-sm font-medium text-gray-900"
      >
        Search
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlass aria-hidden="true" className="scale-115" />
        </div>
        <input
          {...props}
          type="search"
          ref={inputRef}
          id="default-search"
          className="text-medium flex w-full grow rounded-full  border-2 border-gray-400 bg-transparent p-1.5 pl-10 pr-0 text-gray-900 focus:border-black focus:outline-0"
        />
      </div>
    </form>
  );
};

export default Search;
