"use client";

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import Search from "@/components/input/Search/Search";
import * as Icons from "@/data/EventIcons";
import { eventsArray, type EventType } from "@/data/EventList";
import { random } from "remotion";

import { getCalendarEventsWithinRange, getGeneralEvents } from "./Calendaring";

// const DropDownSearch = () => {
//   return (
//     <div className="flex justify-between px-4 lg:px-8">
//       <span className="text-2xl font-medium">category Templates</span>
//       <DropDown
//         defaultState={"Sort By"}
//         options={[
//           "All",
//           "Top-Rated",
//           "Trending",
//           "Rating: 4.5+",
//           "Hot This Week",
//           "New",
//         ]}
//       />
//     </div>
//   );
// };

const SearchBar = () => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const category = searchParams.get("category") ?? undefined;

  const createQueryString = React.useCallback(
    (name: string, value: string | undefined) => {
      const params = new URLSearchParams(searchParams.toString());
      if (value) params.set(name, value);
      else params.delete(name);
      return params.toString();
    },
    [searchParams],
  );
  const setCategory = React.useCallback(
    (category: string | undefined) =>
      router.push(`?${createQueryString("category", category)}`),
    [createQueryString, router],
  );

  // manage category scroll state
  const categoryContainerRef = useRef<HTMLDivElement>(null);
  const handleScroll = React.useCallback(
    (direction: "Left" | "Right") =>
      function () {
        if (!categoryContainerRef.current) return;
        const scaler = direction === "Left" ? -1 : 1;
        categoryContainerRef.current.scrollLeft += 250 * scaler;
      },
    [categoryContainerRef],
  );

  // get event list -> this logic is being moved to server
  const allEvents = useMemo(() => {
    const relevantEvents = getCalendarEventsWithinRange(
      eventsArray,
      10,
      undefined,
      7,
      60,
    );
    const generalEvents = getGeneralEvents(eventsArray, 5);
    return relevantEvents.concat(generalEvents);
  }, []);

  // manage text search state. update url when changed
  const [searchValue, setSearchValue] = useState(search);
  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      router.push(`?${createQueryString("search", searchValue)}`, {
        scroll: false,
      });
    }, 500);
    return () => clearTimeout(delayDebounce);
  }, [createQueryString, router, searchValue]);

  return (
    <div className=" relative w-full justify-between border-b bg-white py-2 md:flex md:px-2">
      <Search
        placeholder="Search by collection name or card"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        className=" w-full shrink-0 px-2 pb-2 md:order-last md:ml-2 md:w-96 md:px-0 md:pb-0"
      />
      <div className="flex w-full px-0.5 pr-2 pt-1 md:pt-0">
        <button onClick={handleScroll("Left")} className="pr-2">
          <Icons.chevronLeft />
        </button>
        <div className="relative flex w-full justify-between overflow-hidden ">
          <div className=" z-10 h-10 w-8 overflow-x-hidden bg-gradient-to-l from-white/10 to-white"></div>
          <div
            ref={categoryContainerRef}
            className="absolute flex w-full space-x-2 overflow-x-hidden scroll-smooth transition-all duration-500 ease-out hover:w-full"
          >
            <CategoryButton
              key="Free"
              eventData={{
                displayText: "Free Cards",
                value: "free",
                TypeOfEvent: "Persistent",
                metadata: {
                  colors: ["#00c264"],
                },
              }}
              currentCategory={category}
              setCategory={setCategory}
            />
            {allEvents.map((data) => (
              <CategoryButton
                key={data.value}
                eventData={data}
                currentCategory={category}
                setCategory={setCategory}
              />
            ))}
          </div>
          <div className=" z-10 h-10 w-8 bg-transparent bg-gradient-to-r from-white/10 to-white"></div>
        </div>
        <button onClick={handleScroll("Right")} className="pr-2">
          <Icons.chevronRight />
        </button>
        <button
          onClick={() =>
            router.push(`?${createQueryString("category", undefined)}`, {
              scroll: false,
            })
          }
          className="flex max-h-10 rounded-full  border-2 border-gray-400 bg-gray-400 px-2 py-1.5 align-middle hover:cursor-pointer hover:border-gray-500  focus:outline-0  md:w-fit "
        >
          <span
            className=" text-md text-center font-medium"
            style={{ opacity: 100 }}
          >
            Clear
          </span>
        </button>
      </div>
    </div>
  );
};

// category button utils
function getPsuedoRandomColor(value: string) {
  return "#" + ((random(value) * 0xffffff) << 0).toString(16).padStart(6, "0");
}

function getColorRangeGradient(
  colors: string[] | undefined,
  typeOfGradient: "linear" | "radial",
  uniqueId: string,
) {
  let buff =
    typeOfGradient === "linear"
      ? "linear-gradient(135deg,"
      : "radial-gradient(";
  if (colors === undefined || colors.length === 0) {
    buff +=
      getPsuedoRandomColor(uniqueId) + ", " + getPsuedoRandomColor(uniqueId);
  } else if (colors.length === 1) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    buff += colors.at(0)! + ", " + colors.at(0)!;
  } else {
    buff += colors.toString();
  }

  // close parenthesis
  buff += ")";
  return buff;
}

const CategoryButton: React.FC<{
  eventData: EventType;
  currentCategory: string | undefined;
  setCategory: (category: string | undefined) => void;
}> = ({ eventData, currentCategory, setCategory }) => {
  // manage mouse hover state
  const [hover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  // styles
  const { value, metadata, displayText } = eventData;
  const firstColor =
    metadata.colors === undefined
      ? getPsuedoRandomColor(value)
      : metadata.colors[0];

  const selected = currentCategory === value;

  const iconContainerStyle = React.useMemo(() => {
    return {
      background: getColorRangeGradient(
        eventData.metadata.colors,
        "linear",
        eventData.value,
      ),
    };
  }, [eventData.metadata.colors, eventData.value]);

  const mainContainerStyle = React.useMemo(() => {
    if (selected)
      return {
        borderColor: firstColor,
        backgroundColor: firstColor,
        paddingLeft: eventData.metadata.icon ? "2.25rem" : "1rem",
      };
    else if (hover)
      return {
        borderColor: `${firstColor}40`,
        backgroundColor: `${firstColor}70`,
        paddingLeft: eventData.metadata.icon ? "2.25rem" : "1rem",
      };
    else
      return {
        borderColor: firstColor,
        backgroundColor: "white",
        paddingLeft: eventData.metadata.icon ? "2.25rem" : "1rem",
      };
  }, [eventData.metadata.icon, firstColor, hover, selected]);

  return (
    <div
      className="relative select-none"
      onClick={() => setCategory(selected ? undefined : value)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="pointer-events-none absolute inset-y-0 left-0 flex  items-center rounded-full  pl-1.5">
        {eventData.metadata.icon ? (
          <div style={iconContainerStyle} className="rounded-full p-1">
            {eventData.metadata.icon}
          </div>
        ) : (
          <></>
        )}
      </div>
      <span
        className="flex whitespace-nowrap rounded-full border-2 p-1.5 pr-4 hover:cursor-pointer focus:border-black focus:outline-0"
        style={mainContainerStyle}
      >
        <span
          className="text-md font-medium"
          style={{
            color: selected ? "white" : hover ? "white" : `${firstColor}`,
          }}
        >
          {displayText}
        </span>
      </span>
    </div>
  );
};
export default SearchBar;
