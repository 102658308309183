//import list from db
//import dates fetcher
//potentially splice and move to utils - half done, still move eventList to db

import { eventsArray, type EventType } from "@/data/EventList";

/**
 *
 * @param eventList [OPTIONAL] array of objects of type 'EventType' to parse through. Defaults to full list of events
 * @param numToReturn [OPTIONAL] maximum number of objects to return. Defaults to 50
 * @param date [OPTIONAL] - date in format "mm-dd-yyyy" to start looking from. Defaults to current date
 * @param rangeBehind [OPTIONAL] - amount of days behind the 'date' to still consider event relevant. Defaults to 30 days
 * @param rangeAhead [OPTIONAL] - amount of days ahead of the 'date' to still consider event relevant. Defaults to 30 days
 * @returns array of objects of type 'EventType'
 */
export function getCalendarEventsWithinRange(
  eventList?: Array<EventType>,
  numToReturn?: number,
  date?: string,
  rangeBehind?: number,
  rangeAhead?: number,
) {
  const DEFAULT_eventList = eventsArray;
  const DEFAULT_numToReturn = 50;
  const DEFAULT_rangeBehind = 30;
  const DEFAULT_rangeAhead = 60;

  eventList = eventList || DEFAULT_eventList;
  numToReturn = numToReturn || DEFAULT_numToReturn;
  rangeBehind = rangeBehind || DEFAULT_rangeBehind;
  rangeAhead = rangeAhead || DEFAULT_rangeAhead;

  //sort list by start date

  //another option would be to
  //precalc what rangeBehind/rangeAhead would be
  //based on the date and check those with the eventDate instead

  const currentDate = date ? new Date(date) : new Date();

  const calendarEventsArray: Array<EventType> = [];

  let counter = 0;

  eventList.forEach((obj) => {
    //console.log(eventDate);
    if (
      "startDate" in obj &&
      obj.TypeOfEvent == "Calendar" &&
      rangeAhead &&
      rangeBehind &&
      numToReturn &&
      counter < numToReturn
    ) {
      const eventStartDate = new Date(obj.startDate);
      let eventEndDate = new Date(obj.endDate);
      if (Number.isNaN(eventEndDate.getTime())) {
        eventEndDate = eventStartDate;
      }
      // console.log(
      //   obj.displayText,
      //   "startdate, enddate",
      //   eventStartDate.getTime(),
      //   eventEndDate.getTime(),
      // );
      if (
        ConvertMsDiffToDay(eventStartDate.getTime(), currentDate.getTime()) <
          rangeAhead &&
        ConvertMsDiffToDay(eventEndDate.getTime(), currentDate.getTime()) >
          -1 * rangeBehind
      ) {
        //console.log("gregorian event", obj.displayText, "pushed");
        calendarEventsArray.push(obj);
        counter++;
      }
    }
  });
  return calendarEventsArray;
}

/**
 *
 * @param eventList [OPTIONAL] array of objects of type 'EventType' to parse through. Defaults to full list of events
 * @param numToReturn [OPTIONAL] maximum number of objects to return. Defaults to 50
 * @returns array of objects of type 'EventType'
 */
export function getGeneralEvents(
  eventList?: Array<EventType>,
  numToReturn?: number,
) {
  const DEFAULT_eventList = eventsArray;
  const DEFAULT_numToReturn = 50;

  eventList = eventList || DEFAULT_eventList;
  numToReturn = numToReturn || DEFAULT_numToReturn;

  const generalEventsArray: Array<EventType> = [];

  let counter = 0;

  eventList.forEach((obj) => {
    if (
      obj.TypeOfEvent == "Persistent" &&
      numToReturn &&
      counter < numToReturn
    ) {
      generalEventsArray.push(obj);
      counter++;
    }
  });
  return generalEventsArray;
}
/**
 * @param eventMS
 * @param dateMS
 * @returns Function returns positive and negative numbers, not an absolute value.
 */
function ConvertMsDiffToDay(eventMS: number, dateMS: number): number {
  const MSinADay = 86400000;
  const diff = eventMS / MSinADay - dateMS / MSinADay;
  //console.log(diff);
  return diff;
}
